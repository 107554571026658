import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const Modal = ({
    ModalBody,
    customHtml = false,
    isModalOpen,
    isModalClose,
    title,
    size,
    modification,
    dataSubmission,
    deleteText,
    confirmationMessage,
    modifier,
    props,
}) => {
    const [onLoad, setLoad] = useState(false)

    useEffect(() => {
        setLoad(true)
    }, [])

    return (
        <div>
            {onLoad && (
                <>
                    <Transition appear show={isModalOpen} as={Fragment}>
                        <Dialog
                            className="relative z-30 w-[640px] mobile:z-[99]"
                            onClose={() => {
                                isModalOpen = false
                            }}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-common-black bg-opacity-60" />
                            </Transition.Child>
                            <div className="fixed inset-0 overflow-y-auto">
                                <div
                                    className={`mx-auto flex min-h-full items-center justify-center p-4 mobile:w-full mobile:items-stretch mobile:p-0 ${
                                        size == 'sm' ? 'w-[382px]' : ''
                                    } ${
                                        size == 'md'
                                            ? 'w-[474px] ipad:w-full'
                                            : ''
                                    } ${
                                        size == 'xl'
                                            ? 'w-[840px] ipad:w-full'
                                            : ''
                                    } ${
                                        size == 'full'
                                            ? 'w-[80%] ipad:w-full'
                                            : ''
                                    } ${
                                        size == null ? 'w-[640px]' : ''
                                    } ${modification}`}
                                >
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel className="w-full rounded-lg bg-common-white mobile:rounded-none">
                                            <div>
                                                {/* Title */}
                                                <Dialog.Title className="relative mb-4 p-6 pb-0 text-xl font-bold leading-6 text-interface-900 mobile:mb-0 mobile:p-4 mobile:text-lg">
                                                    {title}
                                                    <span
                                                        className="absolute right-[18px] top-[12px] cursor-pointer py-3 text-[22px] text-interface-600 mobile:right-3 mobile:top-2"
                                                        onClick={isModalClose}
                                                    >
                                                        <i className="icon-cross"></i>
                                                    </span>
                                                </Dialog.Title>
                                                {/* Body */}
                                                <div
                                                    className={`px-6 pb-6 mobile:px-4 ${modifier}`}
                                                >
                                                    {customHtml ? (
                                                        customHtml
                                                    ) : (
                                                        <ModalBody
                                                            {...props}
                                                            dataSubmission={
                                                                dataSubmission
                                                            }
                                                            deleteText={
                                                                deleteText
                                                            }
                                                            confirmationMessage={
                                                                confirmationMessage
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            )}
        </div>
    )
}

export default Modal
