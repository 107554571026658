/* eslint-disable max-lines */
import { useRouter } from 'next/router'
import React, { useEffect, useState, useRef } from 'react'
import Link from 'next/link'

import { Menu } from '@headlessui/react'
import Button from '@/components/base/Button'
import Separator from '@/components/base/Separator'
import SidebarNavigation from '@/components/base/SidebarNavigation'
import OrganizationSelect from '@/components/base/OrganizationSelect'

import { trackViews } from '@/helpers/views'

import { useSession } from '@/contexts/Session'

import styles from '@/styles/Sidebar.module.css'

// eslint-disable-next-line max-lines-per-function
const Sidebar = (props) => {
    const { asPath } = useRouter()
    const [activeMenu, setActiveMenu] = useState(0)
    const scrollableRef = useRef(null)

    const handleClick = (index) => {
        setActiveMenu(index)
    }
    const handleMenuClick = () => {
        if (scrollableRef.current) {
            setTimeout(() => {
                scrollableRef.current.scrollTo({
                    top: scrollableRef.current.scrollHeight,
                    behavior: 'smooth',
                })
            }, 100) // Adjust the delay as needed
        }
    }

    const { scopes, authToken } = useSession()

    useEffect(() => {
        if (asPath) {
            trackViews(asPath, authToken?.projectId)
        }
    }, [asPath, authToken?.organizationId, authToken?.projectId])

    const menuItems = [
        // {
        //     icon: 'icon-chatbot',
        //     title: 'eCo-Pilot',
        //     path: '/eco-pilot',
        //     pathname: '/eco-pilot',
        //     showSeparator: false,
        // },
        {
            icon: 'icon-star',
            title: 'Favorites',
            path: '/bookmarks',
            pathname: '/bookmarks',
            showSeparator: false,
        },
        {
            icon: 'icon-building',
            title: 'Organization',
            path: '/organization',
            pathname: '/organization',
            showSeparator: false,
        },
        {
            icon: 'icon-globe',
            title: 'Portfolio',
            path: '/portfolio',
            pathname: '/portfolio',
            subMenuItems: [
                {
                    label: 'Net Zero',
                    path: '/portfolio/net-zero-tracking',
                    pathname: '/portfolio/net-zero-tracking',
                },
            ],
            showSeparator: false,
        },
        {
            icon: 'icon-sites',
            title: 'Site',
            path: '/site',
            pathname: '/site',
            subMenuItems: [
                {
                    label: 'Intervals',
                    path: '/site/intervals-baseload',
                    pathname: '/site/intervals-baseload',
                },
                {
                    label: 'Bills',
                    path: '/site/utility-bills',
                    pathname: '/site/utility-bills',
                },
                {
                    label: 'Demand',
                    path: '/site/demand-forecast',
                    pathname: '/site/demand-forecast',
                },
                {
                    label: 'Projects',
                    pathname: '/site/project',
                    path: '/site/project',
                },
                {
                    label: 'Net Zero Tracking',
                    pathname: '/site/net-zero-tracking',
                    path: '/site/net-zero-tracking',
                },
            ],
            showSeparator: false,
        },
        {
            icon: 'icon-chart',
            title: 'Tools',
            path: '/analyze',
            pathname: '/analyze',
            subMenuItems: [
                {
                    label: 'Analyze',
                    path: '/analyze',
                    pathname: '/analyze',
                },
                {
                    label: 'Control',
                    path: '/control',
                    pathname: '/control',
                },
                {
                    label: 'Report',
                    path: '/reports',
                    pathname: '/reports',
                },
                {
                    label: 'Signals',
                    path: '/signals',
                    pathname: '/signals',
                },
            ],
            showSeparator: false,
        },
        {
            icon: 'icon-stats',
            title: 'Track',
            path: '/project-summary',
            pathname: '/project-summary',
            subMenuItems: [
                // {
                //     label: 'Net Zero',
                //     path: '/project-summary/net-zero-tracking',
                //     pathname: '/project-summary/net-zero-tracking',
                // },
                {
                    label: 'Project Dashboard',
                    path: '/project-summary',
                    pathname: '/project-summary',
                },
                {
                    label: 'Project Detail',
                    pathname: '/project-performance',
                    path: '/project-performance',
                },
                {
                    label: 'Project Metrics',
                    path: '/project-summary/tracking',
                    pathname: '/project-summary/tracking',
                },
            ],
        },
        {
            icon: 'icon-bar-chart',
            title: 'View',
            path: '/models',
            pathname: '/models',
            subMenuItems: [
                {
                    label: 'Models',
                    path: '/models',
                    pathname: '/models',
                },
                {
                    label: 'Model Results',
                    path: '/model-results',
                    pathname: '/model-results',
                },
            ],
            showManagementSeparator: false,
        },
        // {
        //     icon: 'icon-hierarchy',
        //     title: 'Admin',
        //     path: '/organization',
        //     pathname: '/organization',
        //     subMenuItems: [
        //         {
        //             label: 'Overview',
        //             path: '/organization',
        //             pathname: '/organization',
        //         },
        //         {
        //             label: 'Manage Admin',
        //             path: '/management/admin-accounts',
        //             pathname: '/management/admin-accounts',
        //         },
        //         {
        //             label: 'Member Management',
        //             path: '/organization/user-accounts',
        //             pathname: '/organization/user-accounts',
        //             children: ['/organization/user-accounts/[id]'],
        //         },
        //         {
        //             label: 'Manage Organizations',
        //             path: '/management/organization',
        //             pathname: '/management/organization',
        //         },
        //         {
        //             label: 'Activity Log',
        //             path: '/activity-log',
        //             pathname: '/activity-log',
        //         },
        //         {
        //             label: 'Data Quality',
        //             path: '/data-quality',
        //             pathname: '/data-quality',
        //         },
        //     ],
        // },
        // {
        //     icon: 'icon-create',
        //     title: 'Create',
        //     path: '/management/organization',
        //     pathname: '/management/organization',
        //     subMenuItems: [
        //         {
        //             label: 'Organization Setup',
        //             path: '/management/organization/setup',
        //             pathname: '/management/organization/[action]/[[...id]]',
        //         },
        //         {
        //             label: 'Create Admin Account',
        //             path: '/management/admin-accounts/create',
        //             pathname: '/management/admin-accounts/[id]',
        //         },
        //         {
        //             label: 'Reports',
        //             path: '/reports',
        //             pathname: '/reports',
        //         },
        //         {
        //             label: 'Project',
        //             path: '/project-summary/create',
        //             pathname: '/project-summary/[id]',
        //         },
        //         {
        //             label: 'Models',
        //             path: '/models/create',
        //             pathname: '/models/[id]',
        //         },
        //     ],
        // },
        // {
        //     icon: 'icon-licenses',
        //     title: 'Licenses',
        //     path: '/management/licenses',
        //     pathname: '/management/licenses',
        //     subMenuItems: [
        //         {
        //             label: 'Manage',
        //             path: '/management/licenses',
        //             pathname: '/management/licenses',
        //         },
        //         {
        //             label: 'Create license',
        //             path: '/management/licenses/create',
        //             pathname: '/management/licenses/[id]',
        //         },
        //     ],
        // },
        // {
        //     icon: 'icon-account',
        //     title: 'Admin Accounts',
        //     path: '/management/admin-accounts',
        //     pathname: '/management/admin-accounts',
        //     subMenuItems: [
        //         {
        //             label: 'Manage',
        //             path: '/management/admin-accounts',
        //             pathname: '/management/admin-accounts',
        //         },
        //         {
        //             label: 'Create account',
        //             path: '/management/admin-accounts/create',
        //             pathname: '/management/admin-accounts/[id]',
        //         },
        //     ],
        // },
    ]
        .filter((navigation) =>
            scopes.includes(navigation?.pathname?.slice(1)?.replace(/\//g, '-'))
        )
        .map((m) => {
            if (m?.subMenuItems?.length) {
                m.subMenuItems = m.subMenuItems?.filter((a) =>
                    scopes.includes(a?.pathname?.slice(1)?.replace(/\//g, '-'))
                )
            }
            return m
        })

    return (
        <>
            <div
                ref={scrollableRef}
                className={`${styles.sidebar} ${styles.animateToggle} ${props.className} scrollable overflow-x-hidden`}
            >
                <div>
                    <Link href="/eco-pilot">
                        <div className={`${styles.ecoPilot}`}>
                            <div className={`${styles.ecoPilotIcon}`}></div>
                            <div className={`${styles.ecoPilotData}`}>
                                <div className="mt-[2px] text-2xl font-semibold text-common-white">
                                    eco-pilot
                                </div>
                                <div className="mt-0 w-full rounded-sm text-xs font-medium text-interface-200">
                                    Chat with your data
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div className="min-w-[150px]">
                        <OrganizationSelect />
                    </div>
                    <SidebarNavigation
                        menuItems={menuItems}
                        activeMenu={activeMenu}
                        handleClick={handleClick}
                    />
                    <Menu
                        as="div"
                        className={`relative mb-3 mt-0 block min-w-[150px]`}
                    >
                        <Menu.Button onClick={handleMenuClick}>
                            <div className={`${styles.sidebarButton}`}>
                                <i className="icon-add-thin" />{' '}
                                <div className="ml-2">Create</div>
                            </div>
                        </Menu.Button>
                        <Menu.Items className="dropdown-menu sidebar-dropdown-menu link-dropdown-menu">
                            {!scopes.includes('member') && (
                                <Menu.Item>
                                    {({ close }) => (
                                        <Button
                                            variant="dropdown-menu"
                                            type="button"
                                            text="Models"
                                            click={() => {
                                                close()
                                            }}
                                            link="/models/create"
                                            modification="text-common-white hover:bg-primary-700 rounded-b-none !pl-3 overflow-hidden"
                                        />
                                    )}
                                </Menu.Item>
                            )}
                            {scopes.includes('admin') && (
                                <>
                                    <Separator modification="bg-interface-700 !mb-0" />
                                    <Menu.Item>
                                        {({ close }) => (
                                            <Button
                                                variant="dropdown-menu"
                                                type="button"
                                                text="Organization Setup"
                                                click={() => {
                                                    close()
                                                }}
                                                link="/management/organization/setup"
                                                modification="text-common-white !font-light hover:bg-primary-700 rounded-none !pl-3 overflow-hidden"
                                            />
                                        )}
                                    </Menu.Item>
                                </>
                            )}
                            <Separator modification="bg-interface-700 !mb-0" />
                            <Menu.Item>
                                {({ close }) => (
                                    <Button
                                        variant="dropdown-menu"
                                        type="button"
                                        text="Project"
                                        click={() => {
                                            close()
                                        }}
                                        link="/project-summary/create"
                                        modification="text-common-white !font-light hover:bg-primary-700 rounded-t-none !pl-3"
                                    />
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>
                </div>
            </div>
        </>
    )
}

export default Sidebar
